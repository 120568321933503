import { useSubscriptionFilterStore } from "@/store/subscriptionFilterStore.ts";

const HERO_SLIDES_SRC = {
  private: {
    src: "/img/private_hero.webp",
    alt: "En person som holder en mobiltelefon på sengen",
    width: 1280,
    height: 853,
  },
  family: {
    src: "/img/family_hero.webp",
    alt: "En stor familie som spiser middag sammen utendørs",
    width: 1280,
    height: 853,
  },
  business: {
    src: "/img/business_hero.webp",
    alt: "Tre personer i jobbantrekk som ser inn i kameraet",
    width: 1279,
    height: 853,
  },
};

const HeroImageSlider = () => {
  const {
    filter: { type },
  } = useSubscriptionFilterStore();

  return (
    <img
      key={type}
      className="object-cover shadow rounded-3xl lg:max-w-xl h-auto aspect-[16/9]"
      width={HERO_SLIDES_SRC[type].width}
      height={HERO_SLIDES_SRC[type].height}
      alt={HERO_SLIDES_SRC[type].alt}
      src={HERO_SLIDES_SRC[type].src}
      loading="eager"
      decoding="async"
    />
  );
};

export default HeroImageSlider;
